body,
html {
  height: 100%;
}

body {
  color: #606060;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#logo-bottom {
  max-width: 100%;
  padding-bottom: 35px;
}

.transparent {
  opacity: 0;
}

#app-window {
  overflow: scroll;

}

.full-height {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.container-fluid {
  display: flex;
  flex-direction: column;
  height: 100%; /* Fuld højde */
}



.page-setup .content {
  color: #fff;

  /*height: 72%;*/
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%; /* Fylder hele højden */
}



.content {
  flex-grow: 1; /* Fylder alt tilgængeligt plads i højden */
  flex-shrink: 0; /* Forhindrer content i at krympe */
  overflow-y: auto; /* Scroll hvis indholdet overstiger tilgængelig plads */
}

.section-top-wrapper {
  /*height: 20%;*/
}

.section-bottom-wrapper {
  flex-shrink: 1; /* Tillader, at bunden krymper under sit indhold */
}

.section-bottom-wrapper #logo {
  position: absolute;
  bottom: 20px;

}

#connection-message {
  width: 100%;
  text-align: center;
  line-height: 50px;
  background-color: red;
  color: #fff;
}

.page-home .content {
  color: #fff;
  /*height: 35%;*/
}

.page-home .section-bottom-wrapper {
  position: relative;
}


.btn-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.btn-small {
  background-color: rgba(255, 255, 255, 1);
  color: #3d3d3d;
  border-radius: 5px;
  padding: 5px 10px;
  border: 0;
  display: inline-block;
}

.btn-small.active {
  opacity: 0.5;
}


.btn-medium {
  background-color: rgba(255, 255, 255, 1);
  color: #3d3d3d;
  border-radius: 35px;
  padding: 10px 15px;
  border: 0;
  display: inline-block;
}


.btn-medium:hover,
.btn-medium:hover {
  text-decoration: none;
}

#logo {
  width: 100%;
}

#company-name {
  font-size: 20px;
}

.transition {
  transition: all 700ms;
}

#qr-wrapper {
  margin-top: 20px;
}

#qr-reader {
  width: 200px;
  height: 200px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;

}

#qr-reader video {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

#qr-container {
  display: inline-block;
  position: relative;
}

#user {
  font-size: 12px;
}

.login-created,
.lead-created,
.user-updated {
  font-size: 11px;
}

.lead-initials,
.user-email {
  font-size: 11px;
  float: right;
  margin-top: 5px;
}

.user-licenses {
  float: right;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-group {
  display: inline-block;
  width: 100%;
}

#forgot-password {
  font-size: 25px;
}

#forgot-password:hover {
  cursor: pointer;
}

#go-back {
  float: right;
  padding: 10px 0;
  cursor: pointer;
}

#upload-wrapper {
  font-size: 14px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

.warning {
  display: inline-block;
  margin-right: 5px;
  font-size: 20px;
}

.users-company-name {
  margin-top: 10px;
  margin-bottom: 5px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  font-weight: bold;
}

#inactive {
  display: inline-block;
  padding: 40px 20px;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
}

.app-licenses {
  width: 40px;
  text-align: center;
  margin-left: 5px;
  padding: 0;
  border-radius: 5px;
}

.user-updated {
  margin-top: 6px;
}

.user-email {
  float: right;
  margin-top: 6px;
}

.logout {
  float: right;
  margin-left: 5px;
}

.app-dropdown {
  border-radius: 5px;
  padding: 5px;
}

.excel-export-settings {
  padding: 0 25px;
}

.edit-note {
  cursor: pointer;
}

.user-row {
  padding: 2px 0;
}

.system-log-name {
  float: right;
}

.log-system-data {
  overflow: scroll;
}

.row-lead {
  overflow: scroll;
}






